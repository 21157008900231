















































































































import Vue from "vue";
import { httpClient } from "@/services";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

export default Vue.extend({
  name: "cantact",
  components: {
    VueHcaptcha,
  },
  data() {
    this.$vuetify.breakpoint.lgAndDown;
    return {
      showAlert: false,
      name: "",
      email: "",
      subject: "",
      message: "",
      verified: false,
      captchaKey: "",
      expired: false,
      loading: false,
      inputProps: {
        filled: true,
        dense: true,
        outlined: true,
        flat: true,
        "background-color": "#fff",
      },
    };
  },
  methods: {
    onVerify(token: string, captchaKey: string) {
      this.verified = true;
      this.captchaKey = captchaKey;
    },
    onExpire() {
      this.verified = false;
      this.expired = true;
    },
    onSubmitEmail(): void {
      this.loading = true;
      const url = "/user/send-contact";
      const body = {
        email: this.email,
        name: this.name,
        message: this.message,
        subject: this.subject,
        hcaptcha: this.captchaKey,
      };
      httpClient
        .post(url, body)
        .then(() => {
          /* Limpiando datos */
          this.loading = false;
          this.name = "";
          this.email = "";
          this.subject = "";
          this.message = "";
          this.verified = false;
          this.captchaKey = "";
          this.expired = false;
          this.showAlert = true;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
});
